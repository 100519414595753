import * as Yup from 'yup'

import { phoneRegExp } from '@/contants'

export const contactFormSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Minimum 2 characters')
    .max(60, 'Maximum 60 characters')
    .required('Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  phone: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Phone is required'),
  companyName: Yup.string()
    .min(5, 'Minimum 5 characters')
    .max(100, 'Maximum 100 characters')
    .required('Company Name is required'),
  messenger: Yup.string()
    .min(40, 'Minimum 40 characters')
    .max(200, 'Maximum 100 characters')
    .required('Messenger is required'),
  recaptcha: Yup.string().required('Invailid capture'),
})
