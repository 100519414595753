import React from 'react'

import { IconLocation, IconMail, IconPhone } from '@/components/Icons'

interface Props {
  city: string
  location: string
  mail: string
  phone: string
}

const BranchCompany: React.FC<Props> = ({ city, location, mail, phone }) => {
  return (
    <>
      <h3>{city}</h3>
      <ul>
        <li>
          <IconLocation width={20} height={20} />
          {location}
        </li>
        <li>
          <IconPhone width={20} height={20} />
          {phone}
        </li>
        <li>
          <IconMail width={22} height={22} />
          {mail}
        </li>
      </ul>
    </>
  )
}

export default BranchCompany
