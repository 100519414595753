import './styles.scss'

import SectionTitle from '../../Item/SectionTitle'

const WhyAI: React.FC = () => {
  return (
    <section className="main-container" id="reason-choose-ai">
      <div className="why-ai">
        <div className="why-ai__title color-white">
          <SectionTitle>Reason to choose AI</SectionTitle>

          <p className="title-desc">
            Blockchain will streamline transactions in crypto finance and NFT
            trading markets, It has a high potential to simplify the processes
            of business entities with its unique features.
          </p>
        </div>

        <div className="why-ai__content color-white ">
          <div className="content">
            <img
              className="content__img"
              src="img/whyAI/ung_dung_thi_giac_may_tinh.jpg"
            />

            <div className="content__detail">
              <p className="content__title">Computer vision</p>

              <p className="content__desc">
                <ul>
                  <li>OCR (Optical character recognition)</li>
                  <li>Vision inspection</li>
                  <li>Smart insight</li>
                  <li>LLM vision model</li>
                </ul>
              </p>
            </div>
          </div>
          <div className="content">
            <img
              className="content__img"
              src="img/whyAI/conversational-ai-messaging.png"
            />

            <div className="content__detail">
              <p className="content__title">
                AI conversational & Nature language procesing (NLP)
              </p>

              <p className="content__desc">
                <ul>
                  <li>Text to speech</li>
                  <li>Chatbots</li>
                  <li>Voice processing</li>
                  <li>Speech to text</li>
                </ul>
              </p>
            </div>
          </div>
          <div className="content">
            <img className="content__img" src="img/whyAI/data_analytics.png" />

            <div className="content__detail">
              <p className="content__title">Data analytics</p>

              <p className="content__desc">
                <ul>
                  <li>Anomaly dectection &amp; Analysis</li>
                  <li>Predictive maintenance</li>
                  <li>Demand forecasting</li>
                  <li>Recommendation</li>
                </ul>
              </p>
            </div>
          </div>
          <div className="content">
            <img
              className="content__img"
              src="img/whyAI/data-labeling.AI_.GettyImages-898172236-1.webp"
            />

            <div className="content__detail">
              <p className="content__title">Data Labeling</p>

              <p className="content__desc">
                <ul>
                  <li>
                    Label videos, pictures, ID cards,passports,driver&rsquo;s
                    licenses
                  </li>
                  <li>Label part of speeches</li>
                  <li>Label named-entity recognition</li>
                  <li>Label conversations and recordings</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhyAI
