import { FC } from 'react'

interface Props {
  width: number
  height: number
  bacground?: string | null
  stroke?: string | null
}

const IconChat: FC<Props> = ({ width, height, bacground, stroke }) => {
  const bacgroundColor = (
    bacground !== undefined ? bacground : '#ffff'
  ) as string
  const strokeColor = (stroke !== undefined ? stroke : '#ffff') as string
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      id="message"
    >
      <path
        fill={bacgroundColor}
        stroke={strokeColor}
        d="M18 6v7c0 1.1-.9 2-2 2h-4v3l-4-3H4c-1.101 0-2-.9-2-2V6c0-1.1.899-2 2-2h12c1.1 0 2 .9 2 2z"
      ></path>
    </svg>
  )
}

export default IconChat
