import './styles.scss'

import BooFCV from '@/assets/img/technology/BOOFCV.png'
import Keras from '@/assets/img/technology/Keras.png'
import Langchain from '@/assets/img/technology/LangChain.png'
import OpenCV from '@/assets/img/technology/OpenCV.png'
import PyTorch from '@/assets/img/technology/PyTorch.png'
import ScikitLearn from '@/assets/img/technology/Scikit-learn.png'
import Spacy from '@/assets/img/technology/SpaCy.png'
import Tensorflow from '@/assets/img/technology/Tensorflow.png'
import SectionTitle from '@/components/Common/Item/SectionTitle'
import TechItem from '@/components/Common/Item/TechItem'

const UsedTechnology: React.FC = () => {
  return (
    <section className="used-technology" id="used-technology">
      <div className="main-container">
        <div className="used-technology__title">
          <SectionTitle>used technology</SectionTitle>
        </div>
        <div className="used-technology__content">
          <TechItem heading="BoofCV" image={BooFCV} />
          <TechItem heading="Keras" image={Keras} />
          <TechItem heading="Langchain" image={Langchain} />
          <TechItem heading="OpenCV" image={OpenCV} />
          <TechItem heading="PyTorch" image={PyTorch} />
          <TechItem heading="ScikitLearn" image={ScikitLearn} />
          <TechItem heading="Spacy" image={Spacy} />
          <TechItem heading="Tensorflow" image={Tensorflow} />
        </div>
      </div>
    </section>
  )
}

export default UsedTechnology
