import React from 'react'
import { Link } from 'react-router-dom'

import BlurLine from '@/components/Common/Item/BlurLine'
import IconPrevMusic from '@/components/Icons/IconPrevMusic'

interface Props {
  title?: string | null
  created_at?: string | null
  content?: string | null
}

const BlogLayout: React.FC<Props> = ({ title, created_at, content }) => {
  return (
    <section className="blog-detail">
      <div className="main-container ">
        {title ? (
          <h1>{title}</h1>
        ) : (
          <h2>Post does not exist or has been deleted</h2>
        )}
        <h3>{created_at}</h3>
        <div className="blog-detail__content">
          {content ? <div dangerouslySetInnerHTML={{ __html: content }} /> : ''}
        </div>
        <Link to={'/blog'} className="come-back">
          <IconPrevMusic width={24} height={24} />
        </Link>
      </div>

      <BlurLine heightTop={90} heightBottom={60}></BlurLine>
    </section>
  )
}

export default BlogLayout
