import { useParams } from 'react-router-dom'

import BlogLayout from './BlogLayout'
import Blogs from './BlogsMokup.json'

const BlogDetailComponent: React.FC = () => {
  const { blogSlug } = useParams()
  const matchedBlog = Blogs.find((post) => post.slug === blogSlug)

  if (!matchedBlog) {
    return <BlogLayout />
  }

  const { title, created_at, content } = matchedBlog
  return <BlogLayout title={title} created_at={created_at} content={content} />
}

export default BlogDetailComponent
