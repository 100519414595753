import { FC } from 'react'

interface Props {
  width: number
  height: number
  color?: string | null
}

const IconX: FC<Props> = ({ width, height, color }) => {
  const strokeColor = (color !== undefined ? color : '#ffff') as string
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      id="X"
      width={width}
      height={height}
    >
      <g
        fill="#ffff"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        className=""
      >
        <path
          d="M13 1 1 13M1 1l12 12"
          fill="#fffff"
          className="color000000 svgShape"
        ></path>
      </g>
    </svg>
  )
}

export default IconX
