import React from 'react'

import AboutUs from '@/components/Common/Pages/AboutUs'
import Bannervideo from '@/components/Common/Pages/BannerVideo'
import NotableProducts from '@/components/Common/Pages/NotableProducts'
import OurPartner from '@/components/Common/Pages/OurPartner'
import ProductProcess from '@/components/Common/Pages/ProductProcess'
import WhyAI from '@/components/Common/Pages/ReasonsToChooseAI'
import UsedTechnology from '@/components/Common/Pages/UsedTechnology'

import Layout from './Layout'

const MainLayout: React.FC = () => {
  return (
    <Layout>
      <Bannervideo />
      <AboutUs />
      <WhyAI />
      <NotableProducts />
      <ProductProcess />
      <UsedTechnology />
      <OurPartner />
    </Layout>
  )
}

export default MainLayout
