import './style.scss'

import React from 'react'

import Circle_Color2 from '@/assets/img/spinner/Circle_Color2.webp'
import Earth_Color2 from '@/assets/img/spinner/Earth_Color2.webp'

interface Props {
  loading: boolean
}

const Spinner: React.FC<Props> = ({ loading }) => {
  return (
    <div
      className={`wrapperLoader d-md-block d-none loading-circular ${
        loading ? 'active' : 'inactive'
      }`}
    >
      <div>
        <img
          className="imageLoader1"
          width={150}
          height={150}
          alt="loading spinner"
          src={Circle_Color2}
        />
        <img
          className="imageLoader2 zoom-in-out-box"
          width={150}
          height={150}
          alt="loading spinner"
          src={Earth_Color2}
        />
      </div>
    </div>
  )
}

export default Spinner
