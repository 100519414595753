import React from 'react'

interface Props {
  children: React.ReactNode
}

const SectionTitle: React.FC<Props> = ({ children }) => {
  return (
    <div className="section-title">
      <h1>{children}</h1>
      <img src="/img/pinkLine.webp" alt="line" />
    </div>
  )
}

export default SectionTitle
