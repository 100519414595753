import React from 'react'

import bapIcon from '@/assets/img/bapIcon.png'
import userIcon from '@/assets/img/userIcon.png'

interface Message {
  text: string
  type: string
  name: string
}

interface MessageProps {
  messages: Message[]
}

const MessageComponent: React.FC<MessageProps> = ({ messages }) => {
  return (
    <>
      {messages.map((message: Message, index: number) => (
        <div key={index} className={`chat-msg ${message.type}`}>
          <div className="msg-title">
            <span className="msg-avatar">
              <img
                alt={`Avatar of ${message.name}`}
                src={message.type === 'self' ? userIcon : bapIcon}
              />
            </span>
            <span className="msg-name">{message.name}</span>
          </div>
          <div className="cm-msg-text">
            <p>{message.text}</p>
          </div>
        </div>
      ))}
    </>
  )
}

export default MessageComponent
