import React from 'react'

import BlurLine from './BlurLine'
interface Props {
  children: React.ReactNode
}

const BannerTitle: React.FC<Props> = ({ children }) => {
  return (
    <div className="banner-title">
      <h1>{children}</h1>
      <BlurLine heightTop={90} heightBottom={60}></BlurLine>
    </div>
  )
}

export default BannerTitle
