import { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { MainLayout } from '@/components/Common/Layout'
import Blog from '@/components/Common/Pages/Blog'
import BlogDetail from '@/components/Common/Pages/BlogDetail'
import ProjectDetail from '@/components/Common/Pages/ProjectDetail'

function PublicRoute() {
  return (
    <Suspense fallback={null}>
      <Routes>
        <Route element={<MainLayout />} path="/" />
        <Route element={<MainLayout />} path="/about-us" />
        <Route element={<MainLayout />} path="/notable-product" />
        <Route element={<MainLayout />} path="/product-process" />
        <Route element={<MainLayout />} path="/contact" />

        <Route element={<Blog />} path="/blog" />
        <Route element={<BlogDetail />} path="/blog/:blogSlug" />

        <Route element={<ProjectDetail />} path="/product/:slug" />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Suspense>
  )
}

export default PublicRoute
