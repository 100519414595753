const useScrollToSection = () => {
  const scrollToSection = (sectionId: string): void => {
    const section = document.getElementById(sectionId)

    if (section) {
      section.scrollIntoView({ behavior: 'smooth' })
    } else {
      window.location.href = '/' + sectionId
    }
  }

  return { scrollToSection }
}

export default useScrollToSection
