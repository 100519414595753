/* eslint-disable react/display-name */
import './style.scss'

import SectionTitle from '@/components/Common/Item/SectionTitle'

import ProcessFrame from './ProcessFrame'

const ProductProcess = () => {
  return (
    <section className="product-process" id="product-process">
      <div className="main-container">
        <SectionTitle>Product Process</SectionTitle>
        <div className="process-frame">
          <div className="process-frame__content">
            <ProcessFrame
              arrowLocation="right"
              color="bg-linear-blue"
              title="01 Client requirements"
              content="BAP prioritizes all of our clients unique business models, plans,
                and ideas. We understand the requirements of AI
                application models."
            />

            <ProcessFrame
              arrowLocation="right"
              color="bg-linear-red"
              title="02 Analysis & Planning"
              content="Our team of experts conducts in-depth analysis of data, available 
              technologies and appropriate methods to meet customer needs. We outline 
              specific steps, work plans and required resources."
            />
            <ProcessFrame
              arrowLocation="bottom"
              color="bg-linear-green"
              title="03  Data Collection & Preprocessing"
              content="This phase is pivotal in the AI product manufacturing process. 
              We collect data from various sources and then process and cleanse it to 
              prepare for the AI model training."
            />
          </div>
          <div className="process-frame__content-reverse">
            <ProcessFrame
              arrowLocation="left"
              color="bg-linear-purple"
              title="04 AI Model Training"
              content="Utilizing intelligent tools and algorithms, we commence 
              the AI model training based on the prepared data. This process 
              undergoes continuous refinement to ensure the model operates efficiently 
              and according to requirements."
            />
            <ProcessFrame
              arrowLocation="left"
              color="bg-linear-dark-green"
              title="05 Validation & Optimization"
              content="The trained AI model undergoes thorough validation to ensure 
              accuracy and performance. We optimize the model by adjusting parameters 
              and processes to achieve the best results."
            />
            <ProcessFrame
              arrowLocation="none"
              color="bg-linear-orange"
              title="06 Deployment & Support"
              content="Finally, we deploy the product for the client.Offer continuous 
              support, ensuring the product operates reliably and meets the initial 
              business objectives."
            />
          </div>
        </div>
        <div className="process-frame-md">
          <div className="process-frame-md__content">
            <ProcessFrame
              arrowLocation="right"
              color="bg-linear-blue"
              title="01 Client requirements"
              content="BAP prioritizes all of our clients unique business models, plans,
                and ideas. We understand the requirements of AI
                application models."
            />

            <ProcessFrame
              arrowLocation="right"
              color="bg-linear-red"
              title="02 Analysis & Planning"
              content="Our team of experts conducts in-depth analysis of data, available 
              technologies and appropriate methods to meet customer needs. We outline 
              specific steps, work plans and required resources."
            />
            <ProcessFrame
              arrowLocation="bottom"
              color="bg-linear-green"
              title="03  Data Collection & Preprocessing"
              content="This phase is pivotal in the AI product manufacturing process. 
              We collect data from various sources and then process and cleanse it to 
              prepare for the AI model training."
            />
            <ProcessFrame
              arrowLocation="left"
              color="bg-linear-purple"
              title="04 AI Model Training"
              content="Utilizing intelligent tools and algorithms, we commence 
              the AI model training based on the prepared data. This process 
              undergoes continuous refinement to ensure the model operates efficiently 
              and according to requirements."
            />
            <ProcessFrame
              arrowLocation="left"
              color="bg-linear-dark-green"
              title="05 Validation & Optimization"
              content="The trained AI model undergoes thorough validation to ensure 
              accuracy and performance. We optimize the model by adjusting parameters 
              and processes to achieve the best results."
            />
            <ProcessFrame
              arrowLocation="none"
              color="bg-linear-orange"
              title="06 Deployment & Support"
              content="Finally, we deploy the product for the client.Offer continuous 
              support, ensuring the product operates reliably and meets the initial 
              business objectives."
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProductProcess
