import './style.scss'

import BannerTitle from '@/components/Common/Item/BannerTitle'
import Layout from '@/components/Common/Layout/MainLayout/Layout'

import BlogComponent from './Blog'

const Blog: React.FC = () => {
  return (
    <Layout>
      <BannerTitle>Blog</BannerTitle>
      <BlogComponent />
    </Layout>
  )
}

export default Blog
