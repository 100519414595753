/* eslint-disable react/display-name */
import './style.scss'

import ListProduct from '@/components/Common/Item/Product'
import SectionTitle from '@/components/Common/Item/SectionTitle'

const NotableProducts = () => {
  return (
    <section className="notable-product" id="notable-product">
      <div className="main-container">
        <SectionTitle>Notable Products</SectionTitle>
        <div className="container">
          <ListProduct />
        </div>
      </div>
    </section>
  )
}

export default NotableProducts
