import './styles.scss'

import SectionTitle from '@/components/Common/Item/SectionTitle'

const OurPartner: React.FC = () => {
  const images = [
    'img/hitachi.webp',
    'img/rakuten.webp',
    'img/TUOT-company.webp',
    'img/styly.webp',
    'img/cyberagent.webp',
    'img/dokidok.webp',
    'img/fujitomi.webp',
    'img/kpmg.webp',
    'img/mediotec.webp',
    'img/mizono.webp',
    'img/Mufg.webp',
    'img/musashi.webp',
    'img/nttdata.webp',
    'img/supportas.webp',
    'img/vingroup.webp',
    'img/jbrand1.webp',
  ]

  return (
    <section className="our-partner main-container" id="our-partner">
      <div className="our-partner__title">
        <SectionTitle>Our partner</SectionTitle>
      </div>
      <div className="our-partner__content">
        {images.map((src, index) => (
          <div key={index} className="logo scale-img">
            <img src={src} alt="company-logo" />
          </div>
        ))}
      </div>
    </section>
  )
}

export default OurPartner
