import React from 'react'

interface Props {
  children: React.ReactNode
}

const ErrorValidateText: React.FC<Props> = ({ children }) => {
  return (
    <div className="absolute font text-sm text-rose-600 font-medium">
      {children}
    </div>
  )
}

export default ErrorValidateText
