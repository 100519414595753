/* eslint-disable react/prop-types */
import { Link } from 'react-router-dom'

import useScrollToSection from '@/components/CustomHook/useScrollToSection'
import { useToggle } from '@/components/CustomHook/useToggle'
import { IconList, IconRound, LogoBAP } from '@/components/Icons'

// eslint-disable-next-line react/prop-types
const Header: React.FC = () => {
  const [togglenavMobile, handleToggleNavMobile] = useToggle(false)
  const [toggleProductMb, handleToggleProductMb] = useToggle(false)

  const { scrollToSection } = useScrollToSection()

  return (
    <>
      <header className="header">
        <nav className="navbar">
          <div className="logo">
            <Link to={'/'}>
              <LogoBAP width={91} height={32} />
            </Link>
          </div>
          <ul className="nav-list">
            <li className="header-dropdown">
              <Link to={''} className="show">
                Product <IconRound width={16} height={16} />
              </Link>

              <div className={'header-dropdown-content'}>
                <Link
                  to={'#'}
                  onClick={() => scrollToSection('notable-product')}
                >
                  Notable Products
                </Link>
                <Link
                  to={'#'}
                  onClick={() => scrollToSection('product-process')}
                >
                  Product process
                </Link>
              </div>
            </li>
            <li>
              <Link onClick={() => scrollToSection('about-us')} to={''}>
                Company
              </Link>
            </li>
            <li>
              <Link onClick={() => scrollToSection('contact')} to={''}>
                Contact
              </Link>
            </li>
            <li>
              <a href={'/blog'}>Blog</a>
            </li>
          </ul>
          <button className="header-menu" onClick={handleToggleNavMobile}>
            <IconList width={24.8} height={18.92} />
          </button>
        </nav>
        <div className={'nav-mobile ' + (togglenavMobile ? 'active' : '')}>
          <ul className={'nav-list ' + (toggleProductMb && 'active')}>
            <li className="header-dropdown" onClick={handleToggleProductMb}>
              <span>
                <Link to={''}>Product </Link>
                <IconRound width={16} height={16} />
              </span>
            </li>
            <ul className="dropdown-item ">
              <li onClick={() => scrollToSection('notable-product')}>
                <Link to={'#'}>Notable Products</Link>
              </li>
              <li onClick={() => scrollToSection('product-process')}>
                <Link to={'#'}>Product process</Link>
              </li>
            </ul>
            <li>
              <Link onClick={() => scrollToSection('about-us')} to={''}>
                Company
              </Link>
            </li>
            <li>
              <a href={'/blog'}>Blog</a>
            </li>
            <li>
              <Link onClick={() => scrollToSection('contact')} to={''}>
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </header>
      <div className="separating-block"></div>
    </>
  )
}

export default Header
