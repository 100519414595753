import React from 'react'

interface Props {
  heightTop: number
  heightBottom: number
}

const BlurLine: React.FC<Props> = ({ heightTop, heightBottom }) => {
  return (
    <>
      <div className="overlay__blur" style={{ height: heightTop + 'px' }}></div>
      <div
        className="overlay__blur"
        style={{
          height: heightBottom + 'px',
          bottom: -heightBottom + 'px',
          transform: 'rotate(180deg)',
        }}
      ></div>
    </>
  )
}

export default BlurLine
