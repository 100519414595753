import './style.scss'

import { useFormik } from 'formik'
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import { useToggle } from '@/components/CustomHook/useToggle'
import { IconChat, IconSend, IconX, LogoBAP } from '@/components/Icons'
import { addMessage } from '@/states/addMessage'
import { RootState } from '@/states/store'

import MessageComponent from './Message'

const ChatBox: React.FC = () => {
  const [toggleButtonChat, handleToggleButtonChat] = useToggle(false)
  const [toggleChatBox, handleToggleChatBox] = useToggle(false)
  const chatRef = useRef<HTMLDivElement>(null)

  const dispatch = useDispatch()
  const messages = useSelector((state: RootState) => state.addMessage.messages)

  const handleToggleChat = () => {
    handleToggleButtonChat()
    handleToggleChatBox()
  }

  const handleReplyMessage = (message: string) => {
    setTimeout(() => {
      const botMessage = {
        text: message,
        type: 'user',
        name: 'BAP AI',
      }
      dispatch(addMessage(botMessage))
    }, 1000)
  }

  const formik = useFormik({
    initialValues: {
      message: '',
    },
    validationSchema: Yup.object().shape({
      message: Yup.string().required(),
    }),
    onSubmit: async (values) => {
      console.log(values)

      const newMessage = {
        text: values.message,
        type: 'self',
        name: 'You',
      }
      dispatch(addMessage(newMessage))

      formik.resetForm()

      handleReplyMessage(values.message)
    },
  })

  useEffect(() => {
    const chatLogs = chatRef.current

    if (chatLogs?.lastElementChild) {
      chatLogs.lastElementChild.scrollIntoView({ behavior: 'smooth' })
    }
  }, [messages])

  return (
    <>
      <div
        className={'btn btn-raised ' + (toggleButtonChat ? 'scale' : '')}
        onClick={() => handleToggleChat()}
      >
        <IconChat width={30} height={30} />
      </div>

      <div className={'chat-box ' + (toggleChatBox ? 'scale' : '')}>
        <div className="chat-box-header">
          <LogoBAP width={84} height={29} />
          <p>AI will answer your questions</p>
          <span className="chat-box-toggle" onClick={() => handleToggleChat()}>
            <IconX width={16} height={16} />
          </span>
        </div>

        <div className="chat-box-body">
          <div className="chat-logs" ref={chatRef}>
            <MessageComponent messages={messages} />
          </div>
        </div>

        <div className="chat-form">
          <form onSubmit={formik.handleSubmit}>
            <input
              type="text"
              name="message"
              className="chat-input"
              placeholder="Please enter your question."
              autoComplete="off"
              onChange={formik.handleChange}
              value={formik.values.message}
            />
            <button type="submit" className="chat-submit">
              <IconSend width={31} height={31} />
            </button>
          </form>
        </div>
      </div>
    </>
  )
}

export default ChatBox
