import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type Message = {
  text: string
  type: string
  name: string
}

type MessageState = {
  messages: Message[]
}

const initialState: MessageState = {
  messages: [],
}

export const addMessageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    addMessage: (state, action: PayloadAction<Message>) => {
      state.messages.push(action.payload)
    },
  },
})

export const { addMessage } = addMessageSlice.actions

export default addMessageSlice.reducer
