import './style.scss'

import { useParams } from 'react-router-dom'

import BannerTitle from '@/components/Common/Item/BannerTitle'
import Products from '@/components/Common/Item/Product'
import ProjectInfors from '@/components/Common/Item/Product/projectInformation.json'
import Layout from '@/components/Common/Layout/MainLayout/Layout'

const ProjectDetail: React.FC = () => {
  const params = useParams()
  const slug = params.slug
  const thisProject = ProjectInfors.find((value) => value.slug === slug)

  return (
    <Layout>
      <BannerTitle>{thisProject && thisProject.name}</BannerTitle>
      <section className="product-detail">
        <div className="main-container">
          <div className="content-product section1 flex justify-around items-center border-solid border-2 border-white bg-green-50 rounded-2xl py-14">
            <div className="image w-5/12 max-h-full">
              <img
                className="object-cover w-full"
                src={thisProject && thisProject.img}
                alt="image of project"
              ></img>
            </div>
            <div className="content w-5/12">
              <h1 className="text-3xl text-blue-900 font-black pb-4">
                Problem
              </h1>
              <hr className="w-28  border-solid border-2 border-blue-700"></hr>
              {thisProject &&
                thisProject.content &&
                thisProject.content.problem && (
                  <div
                    className="text-base text-sky-950 mt-2"
                    dangerouslySetInnerHTML={{
                      __html: thisProject.content.problem,
                    }}
                  />
                )}
            </div>
          </div>
          <div className="content-product section2 flex flex-col items-center border-solid border-2 border-white bg-green-50 rounded-2xl py-14 my-12">
            <div className="content w-10/12">
              <h1 className="text-3xl text-blue-900 font-black pb-4">
                Input/Output
              </h1>
              <hr className="w-28  border-solid border-2 border-blue-700"></hr>
              {thisProject && thisProject.content && (
                <div
                  className="text-base text-sky-950 mt-6"
                  dangerouslySetInnerHTML={{
                    __html: thisProject.content.input_output,
                  }}
                />
              )}
            </div>
          </div>
          <div className="video-demo rounded-xl overflow-clip my-12 w-11/12 mx-auto">
            <video src={thisProject && thisProject.video} controls></video>
          </div>
          <div className="content-product section3 flex flex-col items-center border-solid border-2 border-white bg-green-50 rounded-2xl py-14">
            <div className="content w-10/12 mb-5">
              <h1 className="text-3xl text-blue-900 font-black pb-4">
                Solution
              </h1>
              <hr className="w-28  border-solid border-2 border-blue-700"></hr>
              {thisProject && thisProject.content && (
                <div
                  className="text-base text-sky-950 mt-2"
                  dangerouslySetInnerHTML={{
                    __html: thisProject.content.solution.detail,
                  }}
                />
              )}
            </div>
            {thisProject && thisProject.content.solution.img && (
              <div className="image w-10/12 mx-auto">
                <img
                  className="object-cover w-full"
                  src={thisProject.content.solution.img}
                  alt="image of project"
                ></img>
              </div>
            )}
          </div>
          <div className="content-product section4 flex justify-around items-center border-solid border-2 border-white bg-green-50 rounded-2xl py-14 my-12">
            <div className="content w-10/12">
              <h1 className="text-center text-3xl text-blue-900 font-black pb-4">
                Opportunity
              </h1>
              <hr className="mx-auto w-28  border-solid border-2 border-blue-700"></hr>
              {thisProject &&
                thisProject.content &&
                thisProject.content.opportunity && (
                  <div
                    className="text-base text-sky-950 mt-2"
                    dangerouslySetInnerHTML={{
                      __html: thisProject.content.opportunity,
                    }}
                  />
                )}
            </div>
          </div>
          <p className="other-products text-white text-3xl font-bold underline underline-offset-8 mt-24">
            OTHER PRODUCTS
          </p>
        </div>

        <div className="scroll-products">
          <Products />

          <Products />

          <Products />
        </div>
      </section>
    </Layout>
  )
}

export default ProjectDetail
