/* eslint-disable react/display-name */
import './style.scss'

import { useFormik } from 'formik'
import { useState } from 'react'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ReCAPTCHA from 'react-google-recaptcha'

import SectionTitle from '@/components/Common/Item/SectionTitle'
import ErrorValidateText from '@/components/Common/Pages/Contact/ErrorValidateText'
import customAxios from '@/libs/axios'

import { contactFormSchema } from './ContactFormSchema'

const Contact = () => {
  const [loadingBtn, setLoadingBtn] = useState(false)
  const [resultContact, setResultContact] = useState(false)
  const { REACT_APP_FORMSPREE_ENDPOINT } = process.env
  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
      companyName: '',
      phone: '',
      messenger: '',
      recaptcha: '',
    },
    validationSchema: contactFormSchema,
    onSubmit: async (values) => {
      if (!REACT_APP_FORMSPREE_ENDPOINT) {
        console.error('REACT_APP_FORMSPREE_ENDPOINT is undefined')
      } else {
        setLoadingBtn(true)
        await customAxios
          .post(REACT_APP_FORMSPREE_ENDPOINT, {
            Email: values.email,
            Name: values.name,
            CompanyName: values.companyName,
            Phone: values.phone,
            Messenger: values.messenger,
          })
          .then((response) => {
            console.log(response.data)
            setLoadingBtn(false)
            setResultContact(true)
            formik.resetForm()
          })
          .catch((error) => {
            setLoadingBtn(false)
            console.error(error)
          })
      }
    },
  })

  return (
    <section className="contact" id="contact">
      <div className="main-container">
        <SectionTitle>Contact</SectionTitle>

        <div className="wrapper-contact">
          <div className="wrapper-content">
            <div className="contact-form-sub">
              <div className="wrapper-rl">
                <img src="/img/hand.webp" alt="hand-sub" />
              </div>

              <div className="wrapper-rl">
                <div className="block-sub">
                  <h3>Please contact us first!</h3>
                  <p>
                    Please feel free to ask us anything related to business or
                    technology.
                  </p>
                  <p>Our experts will get back to you as soon as possible.</p>
                  <p>
                    Get all your blockchain questions answered and move your
                    project forward!
                  </p>
                </div>
              </div>
            </div>
            <div className="contact-form-main">
              <form onSubmit={formik.handleSubmit}>
                <div className="ip-part">
                  <input
                    placeholder="Name"
                    name="name"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <ErrorValidateText>{formik.errors.name}</ErrorValidateText>
                  ) : null}
                </div>
                <div className="ip-part">
                  <input
                    placeholder="Email Address"
                    name="email"
                    type="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <ErrorValidateText>{formik.errors.email}</ErrorValidateText>
                  ) : null}
                </div>
                <div className="ip-part">
                  <input
                    placeholder="Company Name"
                    name="companyName"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.companyName}
                  />
                  {formik.touched.companyName && formik.errors.companyName ? (
                    <ErrorValidateText>
                      {formik.errors.companyName}
                    </ErrorValidateText>
                  ) : null}
                </div>
                <div className="ip-part">
                  <input
                    placeholder="Phone Number"
                    name="phone"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <ErrorValidateText>{formik.errors.phone}</ErrorValidateText>
                  ) : null}
                </div>
                <div className="ip-full">
                  <textarea
                    placeholder="Messenger..."
                    name="messenger"
                    onChange={formik.handleChange}
                    value={formik.values.messenger}
                  ></textarea>
                  {formik.touched.messenger && formik.errors.messenger ? (
                    <ErrorValidateText>
                      {formik.errors.messenger}
                    </ErrorValidateText>
                  ) : null}
                </div>
                <div className="ml-2.5 mt-2.5">
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_GOOGLE_SITE_KEY}
                    onChange={(token: string) => {
                      formik.setFieldValue('recaptcha', token || '')
                    }}
                  />
                  {formik.errors.recaptcha && formik.touched.recaptcha && (
                    <ErrorValidateText>
                      {formik.errors.recaptcha}
                    </ErrorValidateText>
                  )}
                </div>
                <div className="ip-full">
                  <button
                    className={'btn-loading' + (loadingBtn && ' load')}
                    type="submit"
                    disabled={loadingBtn}
                    onClick={() => setResultContact(false)}
                  >
                    <span className="span-text">Send</span>
                    <span className="loading-spinner"></span>
                  </button>
                </div>
              </form>
              <div
                className={'result-contact ' + (resultContact ? 'show' : '')}
              >
                Thank you very much for taking the time to contact us. We will
                respond within two business days.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact
