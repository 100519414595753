import React from 'react'

interface Props {
  heading: string
  image: string
}

const TechItem: React.FC<Props> = ({ heading, image }) => {
  return (
    <div className="technology-wrapper">
      <div className="technology-border shadow-effect">
        <div className="technology-inner">
          <p className="technology-inner__heading">{heading}</p>
          <img src={image}></img>
        </div>
      </div>
    </div>
  )
}

export default TechItem
