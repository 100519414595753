import './style.scss'

import Layout from '@/components/Common/Layout/MainLayout/Layout'

import BlogDetailComponent from './BlogDetail'

const BlogDetail: React.FC = () => {
  return (
    <Layout>
      <BlogDetailComponent />
    </Layout>
  )
}

export default BlogDetail
