/* eslint-disable react/display-name */
import './style.scss'

import { useEffect, useState } from 'react'
import { IoIosArrowForward } from 'react-icons/io'
import { IoIosArrowBack } from 'react-icons/io'
import { TiTickOutline } from 'react-icons/ti'

import SectionTitle from '@/components/Common/Item/SectionTitle'

import Slides from './slides'

const AboutUs = () => {
  const [current, setCurrent] = useState(0)
  const autoSlide = true
  const pre = () =>
    setCurrent((prevCurrent) =>
      prevCurrent === 0 ? Slides.length - 1 : prevCurrent - 1
    )

  const next = () =>
    setCurrent((current) => (current === Slides.length - 1 ? 0 : current + 1))

  useEffect(() => {
    if (!autoSlide) return
    const slideInterval = setInterval(next, 3000)
    return () => clearInterval(slideInterval)
  }, [autoSlide])

  return (
    <section className="about-us" id="about-us">
      <div className="main-container">
        <SectionTitle>About us</SectionTitle>
        <div className="row-container">
          <div className="content-left">
            <p className="p-lg">Team of 20+ certified AI developers.</p>
            <p className="p-lg">
              BAP&apos;s products and services are innovative and one step ahead
              of our competitors.
            </p>
            <p className="color-white p-sm">
              <TiTickOutline className="inline-block" />
              Our expertise extends to BPO data labeling for enhanced accuracy
              and cost efficiency.
            </p>
            <p className="color-white p-sm">
              <TiTickOutline className="inline-block" />
              BAP aims for excellence in Data Science, Deep Learning, and
              Machine Learning !
            </p>
            <p className="color-white p-sm">
              <TiTickOutline className="inline-block" />
              BAP has a team of skilled AI developers in the field of AI
              development.
            </p>
          </div>
          <div className="image-right">
            <div
              className="flex transition-transform ease-out duration-500 "
              style={{ transform: `translateX(-${current * 100}%)` }}
            >
              {Slides.map((s: string, index: number) => (
                <img key={index} src={s} alt={`slide-${index}`} />
              ))}
            </div>

            <div className="absolute inset-0 flex items-center justify-between p-3">
              <button
                onClick={pre}
                className="p-1 rounded-full shadow bg-white/80 text-gray-800 hover:bg-white"
              >
                <IoIosArrowBack />
              </button>
              <button
                onClick={next}
                className="p-1 rounded-full shadow bg-white/80 text-gray-800 hover:bg-white"
              >
                <IoIosArrowForward />
              </button>
            </div>

            <div className="absolute bottom-4 right-0 left-0">
              <div className="flex items-center justify-center gap-2">
                {Slides.map((s: string, index: number) => (
                  <div
                    key={index} // Add the key prop here
                    className={`transition-all w-2 h-2 bg-white rounded-full ${
                      current === index ? 'p-2' : 'bg-opacity-50'
                    }`}
                  ></div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutUs
