import { useEffect } from 'react'

const useScrollDefault = (sectionId: string): void => {
  useEffect(() => {
    const section = document.getElementById(sectionId)

    if (section) {
      section.scrollIntoView({ behavior: 'smooth' })
    }
  }, [sectionId])
}

export default useScrollDefault
