import { useEffect, useState } from 'react'

const useScrollToTop = (scrollThreshold = 200) => {
  const [goToTop, setGoToTop] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      setGoToTop(window.scrollY > scrollThreshold)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollThreshold])

  const handleGoTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  return { goToTop, handleGoTop }
}

export default useScrollToTop
