import './style.scss'

import { useState } from 'react'
import { LuPlayCircle } from 'react-icons/lu'

import Popup from './popup'
import data from './projectInformation.json'

const ListProduct = () => {
  const [url, setUrl] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  const openPopup = (videoUrl: string) => {
    setUrl(videoUrl)
    setIsOpen(true)
  }

  const closePopup = () => {
    setUrl('')
    setIsOpen(false)
  }

  return (
    <>
      <Popup video={url} isOpen={isOpen} onClose={closePopup} />
      <div className="products">
        {data &&
          data.map((infor, index: number) => (
            <div key={index} className="card">
              <div className="face face1">
                <div className="content">
                  <img src={infor.img} />
                  <LuPlayCircle
                    onClick={() => {
                      infor.video !== '' && openPopup(infor.video)
                    }}
                  />
                  <div className="product-name">
                    <h3>{infor.name}</h3>
                  </div>
                </div>
              </div>
              <div className="face face2">
                <div className="content">
                  <p>{infor.descriptions}</p>
                  <a href={`/product/${infor.slug}`}>Read More</a>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  )
}

export default ListProduct
