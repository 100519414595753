import React from 'react'

interface Props {
  color: string
  title: string
  content: string
  arrowLocation: string // left right bottom none
}

const ProcessFrame: React.FC<Props> = ({
  color,
  title,
  content,
  arrowLocation,
}) => {
  return (
    <div className={`block ${color}`}>
      <h3>{title}</h3>
      <div className="content-child">{content}</div>
      <div className={`arrow-animate ${arrowLocation}`}>
        <span className="arrow prinema"></span>
        <span className="arrow segunda"></span>
      </div>
    </div>
  )
}

export default ProcessFrame
