import './style.scss'

const Bannervideo: React.FC = () => {
  return (
    <section id="banner">
      <div className="banner-video">
        <video
          className="video"
          src="video/banner/banner.mp4"
          preload=""
          loop={true}
          autoPlay
          muted
        ></video>
        <div className="content-container">
          <p className="color-white title">Empowering Tomorrow, Today:</p>
          <div className="color-white child-content">
            <p>Crafting Intelligent Solutions for a Connected World.</p>
            <p>Welcome to BAP AI, where innovation meets intelligence.</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Bannervideo
