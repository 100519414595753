import { FC } from 'react'

interface MySVGProps {
  width: number
  height: number
}

const LogoBAP: FC<MySVGProps> = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 91 32"
      fill="none"
    >
      <mask
        id="mask0_40_214"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="91"
        height="32"
      >
        <path d="M91 0H0V32H91V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_40_214)">
        <path
          d="M17.1646 20.7249C18.3429 21.1914 19.4883 21.5147 20.5271 21.7439C20.7077 20.7003 20.835 19.5175 20.8473 18.257C20.8473 17.8355 20.8432 17.4221 20.8185 17.0088C20.5188 17.2871 20.2109 17.5572 19.8948 17.8109C19.94 17.9296 19.9605 18.0565 19.9564 18.1875C19.94 18.74 19.476 19.1779 18.9218 19.1615C18.7001 19.1533 18.4948 19.0755 18.3306 18.9487C17.5998 19.4275 16.832 19.8531 16.0273 20.2338C16.3968 20.4056 16.7746 20.5693 17.1646 20.7208V20.7249Z"
          fill="white"
        />
        <path
          d="M21.2444 7.95642C20.1564 7.26068 18.9124 6.78184 17.5781 6.5813C18.0174 7.16655 18.4198 7.76406 18.7852 8.37795C19.5775 8.18969 20.3987 8.05055 21.2485 7.95642H21.2444Z"
          fill="white"
        />
        <path
          d="M19.3782 9.46632C19.4398 9.45814 19.5014 9.45403 19.563 9.45403C20.1172 9.4704 20.5565 9.93288 20.5401 10.4854C20.5319 10.7923 20.3841 11.0624 20.1624 11.2384C20.7166 12.6913 21.0861 14.2137 21.2709 15.7894C22.4903 14.5002 23.5372 13.0105 24.4158 11.3244C23.7917 10.2071 22.9501 9.22486 21.9401 8.44727C21.0492 8.52502 20.0762 8.66008 19.0703 8.88927C19.1771 9.08162 19.2797 9.27397 19.3782 9.46632Z"
          fill="white"
        />
        <path
          d="M20.5391 24.2279C21.3314 23.8146 22.0622 23.2866 22.7027 22.6727C22.1854 22.6114 21.6024 22.5254 20.9743 22.4026C20.8388 23.082 20.6869 23.6959 20.5391 24.2279Z"
          fill="white"
        />
        <path
          d="M21.4027 18.2818C21.3903 19.5791 21.259 20.7864 21.0742 21.8546C21.8707 22.0101 22.5892 22.1043 23.1927 22.1656C24.6296 20.5654 25.5288 18.4741 25.5945 16.1577C25.6396 14.6475 25.3235 13.2069 24.7241 11.9219C23.7798 13.653 22.6508 15.1796 21.3411 16.4974C21.3862 17.0826 21.4068 17.6802 21.4027 18.2777V18.2818Z"
          fill="white"
        />
        <path
          d="M18.5351 10.4277C18.5433 10.133 18.6829 9.87104 18.8923 9.69096C18.7773 9.46587 18.6583 9.24078 18.531 9.01978C16.8272 9.45769 15.0495 10.1739 13.4195 11.3076C11.9005 12.3635 10.6278 13.6936 9.60547 15.2897C11.182 17.2091 13.1116 18.7561 15.3861 19.9184C16.3099 19.5132 17.1844 19.0426 18.0096 18.5105C17.9644 18.3919 17.9439 18.265 17.948 18.134C17.9644 17.5815 18.4283 17.1436 18.9826 17.16C19.2084 17.1682 19.4137 17.2459 19.5738 17.3769C19.9885 17.0413 20.3867 16.6852 20.7685 16.3128C20.6207 14.6144 20.2512 12.9937 19.6682 11.459C19.6149 11.4672 19.5656 11.4713 19.5122 11.4672C18.958 11.4508 18.5187 10.9884 18.5351 10.4359V10.4277Z"
          fill="white"
        />
        <path
          d="M19.8619 24.5516C20.0918 24.4533 20.3176 24.3469 20.5393 24.2323L19.8619 24.5516C19.8619 24.5516 19.866 24.5352 19.8701 24.5311C20.0548 23.9213 20.256 23.1642 20.4284 22.2965C19.3651 22.0633 18.1909 21.7318 16.9839 21.257C16.4296 21.0401 15.8918 20.7986 15.3704 20.5408C14.8325 20.77 14.2824 20.9787 13.7158 21.1629C12.0408 21.7154 10.4437 22.006 9.10938 22.1533C10.7762 24.0195 13.1821 25.2268 15.8877 25.3046C17.2959 25.3455 18.6425 25.0754 19.8619 24.5557V24.5516Z"
          fill="white"
        />
        <path
          d="M14.722 20.1973C12.8704 19.1905 11.2323 17.9135 9.83228 16.3829C9.65164 16.1864 9.4751 15.9859 9.30266 15.7812C9.20824 15.941 9.11381 16.1046 9.02349 16.2724C9.28624 16.4606 9.45046 16.7717 9.44225 17.1154C9.42583 17.6679 8.9619 18.1058 8.40765 18.0896C8.32964 18.0896 8.25574 18.0772 8.18595 18.0567C7.96014 18.6174 7.75486 19.2027 7.57422 19.8084C7.87393 20.4591 8.24343 21.069 8.67862 21.6297C10.054 21.4987 11.7619 21.2163 13.5601 20.6229C13.9543 20.4919 14.3443 20.3527 14.722 20.2013V20.1973Z"
          fill="white"
        />
        <path
          d="M7.23828 12.7936L7.49806 12.1306C7.40322 12.3475 7.31663 12.5685 7.23828 12.7936Z"
          fill="white"
        />
        <path
          d="M7.6957 17.766C7.52327 17.5818 7.42063 17.3281 7.42884 17.0579C7.44526 16.5054 7.90919 16.0675 8.46344 16.0839C8.47166 16.0839 8.47987 16.0839 8.48397 16.0839C8.62356 15.822 8.77136 15.5682 8.92327 15.3186C8.30743 14.5328 7.74497 13.6938 7.23588 12.7935C6.92796 13.6816 6.74732 14.6269 6.71858 15.6173C6.68573 16.8001 6.87048 17.9419 7.23998 18.9937C7.37957 18.5722 7.53148 18.1629 7.6957 17.766Z"
          fill="white"
        />
        <path
          d="M13.1 10.8495C14.5944 9.81 16.3188 9.02422 18.2361 8.51674C17.8173 7.82509 17.3452 7.14982 16.8279 6.49909C16.6965 6.49091 16.5651 6.48272 16.4337 6.47863C12.4636 6.36404 8.99443 8.7091 7.5 12.1305C8.0132 13.0964 8.59208 13.9967 9.23666 14.8316C10.2795 13.2478 11.5727 11.9177 13.1041 10.8536L13.1 10.8495Z"
          fill="white"
        />
        <path
          d="M18.3104 3.86356C21.1966 4.37514 23.8078 5.90986 25.6676 8.18535C26.033 8.63144 26.3655 9.10209 26.657 9.58501C27.0594 10.248 27.7943 10.5877 28.521 10.5181C28.8002 10.4895 29.0834 10.4035 29.338 10.248C30.2617 9.69142 30.5573 8.49638 29.999 7.57555C29.6131 6.9371 29.1738 6.31503 28.6934 5.72979C26.2424 2.72992 22.8019 0.708175 18.996 0.0328964C17.9327 -0.155363 16.9186 0.548563 16.7298 1.60855C16.5409 2.66853 17.2471 3.6794 18.3104 3.86766V3.86356Z"
          fill="url(#paint0_linear_40_214)"
        />
        <path
          d="M11.7197 4.50163C12.2493 4.297 12.8035 4.12921 13.3578 3.99824C14.4088 3.75269 15.0616 2.70907 14.8153 1.66137C14.5689 0.613662 13.522 -0.032968 12.471 0.208495C11.7361 0.380384 11.0094 0.601384 10.3074 0.875588C6.70676 2.26298 3.72202 4.90271 1.89504 8.30774C1.38595 9.25723 1.74724 10.4359 2.69562 10.9434C3.0487 11.1317 3.43463 11.2012 3.80413 11.1644C4.43228 11.103 5.01938 10.7388 5.33961 10.1454C6.72319 7.56289 8.98945 5.55752 11.7156 4.50572L11.7197 4.50163Z"
          fill="url(#paint1_linear_40_214)"
        />
        <path
          d="M3.95769 17.0705C3.94127 16.9109 3.93306 16.7513 3.92485 16.5917C3.892 16.0228 3.90021 15.4498 3.94538 14.8769C4.03159 13.8046 3.23511 12.8674 2.15945 12.7774C1.08379 12.6914 0.143616 13.4854 0.0532935 14.5576C-0.00829013 15.3066 -0.0165012 16.0678 0.0286601 16.8168C0.0409768 17.005 0.0532935 17.2178 0.0738214 17.4429C0.431006 21.1017 1.9747 24.4331 4.53658 27.0728C4.96766 27.5148 5.55887 27.7112 6.12954 27.658C6.55241 27.6171 6.96708 27.437 7.29552 27.1219C8.07148 26.373 8.092 25.1411 7.34068 24.3717C5.39875 22.3663 4.22866 19.8453 3.95769 17.0705Z"
          fill="url(#paint2_linear_40_214)"
        />
        <path
          d="M21.5347 26.8682C20.221 27.5066 18.8086 27.9036 17.347 28.0468C15.9019 28.186 14.4608 28.0755 13.0649 27.7113C12.5189 27.568 11.9688 27.3838 11.4391 27.1628C10.4456 26.7495 9.30013 27.216 8.88547 28.2065C8.4708 29.1969 8.93884 30.3387 9.93239 30.7521C10.6303 31.0426 11.3529 31.2882 12.0796 31.4764C13.9189 31.9553 15.8198 32.1067 17.7248 31.9184C19.6544 31.7302 21.5142 31.2063 23.2509 30.3633C24.2198 29.8926 24.6221 28.7262 24.15 27.7604C23.6778 26.7945 22.5078 26.3934 21.5388 26.8641L21.5347 26.8682Z"
          fill="url(#paint3_linear_40_214)"
        />
        <path
          d="M32.0814 13.5508C31.9213 12.4867 30.9277 11.7541 29.8603 11.9137C28.7928 12.0733 28.0579 13.0637 28.2181 14.1278C28.4357 15.5684 28.3905 17.0295 28.0826 18.466C27.7829 19.882 27.2409 21.2203 26.4732 22.4358C26.1735 22.9146 25.8286 23.3771 25.4591 23.815C24.7612 24.6335 24.8638 25.8654 25.689 26.557C26.5143 27.2528 27.7459 27.1505 28.4398 26.3278C28.9283 25.7508 29.3799 25.1369 29.7782 24.5025C30.7882 22.8982 31.5025 21.1343 31.9008 19.2681C32.3031 17.3773 32.3606 15.4497 32.0773 13.5467L32.0814 13.5508Z"
          fill="url(#paint4_linear_40_214)"
        />
        <path
          d="M14.866 1.91542C14.9686 2.98768 14.1844 3.94125 13.1088 4.04357C12.0331 4.14588 11.0765 3.36419 10.9739 2.29193C10.8712 1.21967 11.6554 0.2661 12.7311 0.163785C13.8067 0.0614704 14.7633 0.843156 14.866 1.91542Z"
          fill="white"
        />
        <path
          d="M30.2761 8.39003C30.3787 9.46229 29.5946 10.4159 28.5189 10.5182C27.4433 10.6205 26.4867 9.8388 26.384 8.76654C26.2814 7.69428 27.0656 6.74071 28.1412 6.63839C29.2169 6.53608 30.1735 7.31777 30.2761 8.39003Z"
          fill="white"
        />
        <path
          d="M28.8972 24.8793C28.9998 25.9515 28.2157 26.9051 27.14 27.0074C26.0644 27.1097 25.1078 26.3281 25.0051 25.2558C24.9025 24.1835 25.6866 23.23 26.7623 23.1277C27.838 23.0253 28.7946 23.807 28.8972 24.8793Z"
          fill="white"
        />
        <path
          d="M12.6277 28.7714C12.7303 29.8436 11.9461 30.7972 10.8705 30.8995C9.79483 31.0018 8.83823 30.2202 8.73559 29.1479C8.63295 28.0756 9.41712 27.1221 10.4928 27.0197C11.5684 26.9174 12.525 27.6991 12.6277 28.7714Z"
          fill="white"
        />
        <path
          d="M3.94017 14.5331C4.04281 15.6054 3.25865 16.5589 2.18299 16.6612C1.10733 16.7636 0.15073 15.9819 0.048091 14.9096C-0.0545483 13.8374 0.729616 12.8838 1.80528 12.7815C2.88094 12.6791 3.83753 13.4608 3.94017 14.5331Z"
          fill="white"
        />
        <path
          d="M39.2383 27.7477H40.5517C41.2037 27.7477 41.6968 27.4264 41.6968 26.8245C41.6968 26.3569 41.3913 26.0715 40.9703 26.0235V25.9972C41.2806 25.918 41.5212 25.6878 41.5212 25.2802C41.5212 24.7694 41.1339 24.4744 40.5686 24.4744H39.2383V27.7477ZM39.818 24.9252H40.4411C40.7322 24.9252 40.9463 25.0691 40.9463 25.3785C40.9463 25.6998 40.7201 25.8509 40.2679 25.8509H39.818V24.9252ZM39.818 26.2753H40.4844C40.8621 26.2753 41.1026 26.4552 41.1026 26.8005C41.1026 27.189 40.7875 27.2969 40.3256 27.2969H39.818V26.2753Z"
          fill="white"
        />
        <path
          d="M42.5098 27.7477L42.789 26.8652H43.922L44.201 27.7477H44.8241L43.7055 24.4744H43.0439L41.9229 27.7477H42.5098ZM43.3686 25.0619L43.7969 26.424H42.9165L43.347 25.0619H43.3686Z"
          fill="white"
        />
        <path
          d="M45.2285 24.4744V27.7477H45.8107V26.6086H46.5035C47.1867 26.6086 47.5981 26.165 47.5981 25.5439C47.5981 24.9324 47.1915 24.4744 46.5083 24.4744H45.2285ZM45.8107 24.9468H46.4121C46.785 24.9468 47.0063 25.1627 47.0063 25.5439C47.0063 26.0163 46.7176 26.141 46.2317 26.141H45.8107V24.9468Z"
          fill="white"
        />
        <path
          d="M48.8369 26.8318C48.8465 27.2778 49.1544 27.8125 50.0806 27.8125C50.8407 27.8125 51.3603 27.4433 51.3603 26.8006C51.3603 26.2323 50.9779 25.9997 50.4871 25.8869L49.9579 25.7599C49.6572 25.6903 49.5056 25.544 49.5056 25.3354C49.5056 25.0453 49.751 24.8726 50.1166 24.8726C50.5304 24.8726 50.7397 25.098 50.7613 25.3642H51.3146C51.305 24.7623 50.783 24.4026 50.1215 24.4026C49.4719 24.4026 48.9211 24.7503 48.9211 25.381C48.9211 25.9181 49.3035 26.1723 49.7366 26.2706L50.2634 26.3977C50.6098 26.4817 50.7782 26.6087 50.7782 26.8509C50.7782 27.1483 50.5376 27.3401 50.1215 27.3401C49.6668 27.3401 49.4262 27.1051 49.4022 26.8318H48.8369Z"
          fill="white"
        />
        <path
          d="M52.9332 25.1675C52.1947 25.1675 51.7881 25.6807 51.7881 26.3761V26.5728C51.7881 27.2946 52.1923 27.791 52.9332 27.791C53.6717 27.791 54.0735 27.2946 54.0735 26.5704V26.3737C54.0735 25.6783 53.6693 25.1675 52.9332 25.1675ZM52.9332 25.6136C53.3181 25.6136 53.5009 25.9325 53.5009 26.3785V26.5752C53.5009 27.0092 53.3205 27.3401 52.9332 27.3401C52.5435 27.3401 52.3607 27.0092 52.3607 26.5752V26.3785C52.3607 25.9349 52.5459 25.6136 52.9332 25.6136Z"
          fill="white"
        />
        <path
          d="M54.6514 27.7479H55.2201V24.302H54.6514V27.7479Z"
          fill="white"
        />
        <path
          d="M58.0626 25.2104H57.4948V26.7331C57.4948 27.1024 57.2663 27.3015 56.9584 27.3015C56.6842 27.3015 56.4364 27.1744 56.4364 26.7403V25.2104H55.8662V26.8938C55.8662 27.4861 56.2247 27.7859 56.7467 27.7859C57.1629 27.7859 57.3986 27.5868 57.4804 27.3878H57.5069V27.7475H58.0626V25.2104Z"
          fill="white"
        />
        <path
          d="M58.9165 25.2106H58.5508V25.659H58.9068V27.0331C58.9068 27.5439 59.0897 27.7645 59.7151 27.7645C59.8306 27.7645 59.934 27.7525 59.9797 27.7453V27.3161C59.9485 27.3209 59.9028 27.3209 59.8546 27.3209C59.5756 27.3209 59.4697 27.2441 59.4697 26.9755V25.659H59.9653V25.2106H59.4697V24.5967H58.9165V25.2106Z"
          fill="white"
        />
        <path
          d="M60.5667 27.7477H61.132V25.2106H60.5667V27.7477ZM60.5186 24.5871C60.5186 24.7694 60.6677 24.8989 60.8482 24.8989C61.031 24.8989 61.1801 24.7694 61.1801 24.5871C61.1801 24.4049 61.031 24.2754 60.8482 24.2754C60.6677 24.2754 60.5186 24.4049 60.5186 24.5871Z"
          fill="white"
        />
        <path
          d="M62.8638 25.1675C62.1252 25.1675 61.7188 25.6807 61.7188 26.3761V26.5728C61.7188 27.2946 62.1228 27.791 62.8638 27.791C63.6023 27.791 64.004 27.2946 64.004 26.5704V26.3737C64.004 25.6783 63.5999 25.1675 62.8638 25.1675ZM62.8638 25.6136C63.2487 25.6136 63.4315 25.9325 63.4315 26.3785V26.5752C63.4315 27.0092 63.2511 27.3401 62.8638 27.3401C62.4741 27.3401 62.2912 27.0092 62.2912 26.5752V26.3785C62.2912 25.9349 62.4765 25.6136 62.8638 25.6136Z"
          fill="white"
        />
        <path
          d="M64.5625 27.7478H65.1302V26.2443C65.1302 25.9157 65.3107 25.6591 65.6691 25.6591C65.9698 25.6591 66.2031 25.8342 66.2031 26.2347V27.7478H66.7709V26.1172C66.7709 25.4961 66.4004 25.1699 65.8808 25.1699C65.4598 25.1699 65.2265 25.4002 65.1423 25.5704H65.1206V25.2107H64.5625V27.7478Z"
          fill="white"
        />
        <path
          d="M68.1074 26.8676C68.1074 27.3928 68.4682 27.8124 69.1274 27.8124C69.7649 27.8124 70.1906 27.4623 70.1906 26.7309V24.4744H69.6085V26.7549C69.6085 27.1338 69.4184 27.2945 69.1226 27.2945C68.8772 27.2945 68.6775 27.1362 68.6703 26.8676H68.1074Z"
          fill="white"
        />
        <path
          d="M72.2701 27.4385H72.2917V27.7478H72.8306V26.0236C72.8306 25.4481 72.4 25.1699 71.8275 25.1699C71.1298 25.1699 70.846 25.5416 70.8243 25.9709H71.3584C71.3752 25.7551 71.5364 25.6064 71.8154 25.6064C72.1041 25.6064 72.2677 25.7623 72.2677 26.0404V26.2754H71.6326C71.0456 26.2778 70.7305 26.5656 70.7305 27.0188C70.7305 27.4912 71.0721 27.7862 71.5652 27.7862C71.9405 27.7862 72.1618 27.6303 72.2701 27.4385ZM71.7433 27.3617C71.5147 27.3617 71.2982 27.2394 71.2982 26.9828C71.2982 26.779 71.4402 26.6375 71.7385 26.6375H72.2677V26.9133C72.2677 27.1795 72.0392 27.3617 71.7433 27.3617Z"
          fill="white"
        />
        <path
          d="M74.7789 27.7815C75.3635 27.7815 75.7628 27.345 75.7628 26.5897V26.3667C75.7628 25.6041 75.3587 25.1748 74.791 25.1748C74.4446 25.1748 74.1871 25.3403 74.0499 25.5849H74.0284V25.2108H73.4727V28.604H74.0428V27.3906H74.0645C74.1944 27.6256 74.4446 27.7815 74.7789 27.7815ZM74.6298 27.3211C74.2665 27.3211 74.038 27.0309 74.038 26.5609V26.3834C74.038 25.9254 74.2641 25.6353 74.6226 25.6353C74.9738 25.6353 75.1831 25.9062 75.1831 26.3858V26.5609C75.1831 27.0573 74.9594 27.3211 74.6298 27.3211Z"
          fill="white"
        />
        <path
          d="M77.73 27.4385H77.7517V27.7478H78.2905V26.0236C78.2905 25.4481 77.8599 25.1699 77.2874 25.1699C76.5898 25.1699 76.3059 25.5416 76.2843 25.9709H76.8183C76.8351 25.7551 76.9963 25.6064 77.2754 25.6064C77.564 25.6064 77.7276 25.7623 77.7276 26.0404V26.2754H77.0925C76.5056 26.2778 76.1904 26.5656 76.1904 27.0188C76.1904 27.4912 76.532 27.7862 77.0252 27.7862C77.4005 27.7862 77.6218 27.6303 77.73 27.4385ZM77.2032 27.3617C76.9747 27.3617 76.7582 27.2394 76.7582 26.9828C76.7582 26.779 76.9001 26.6375 77.1984 26.6375H77.7276V26.9133C77.7276 27.1795 77.4991 27.3617 77.2032 27.3617Z"
          fill="white"
        />
        <path
          d="M78.9287 27.7478H79.4964V26.2443C79.4964 25.9157 79.6769 25.6591 80.0353 25.6591C80.336 25.6591 80.5693 25.8342 80.5693 26.2347V27.7478H81.1371V26.1172C81.1371 25.4961 80.7666 25.1699 80.247 25.1699C79.826 25.1699 79.5927 25.4002 79.5085 25.5704H79.4868V25.2107H78.9287V27.7478Z"
          fill="white"
        />
        <path
          d="M51.0953 12.0975C52.015 11.8519 52.7417 11.3935 53.2713 10.7223C53.805 10.0512 54.0719 9.26537 54.0719 8.36909C54.0719 7.47281 53.8091 6.71569 53.2836 6.06497C52.7581 5.41015 52.015 4.90676 51.0584 4.5548C50.0977 4.20284 48.9686 4.02686 47.6754 4.02686H39.7598C39.6038 4.02686 39.4807 4.14963 39.4807 4.30515V6.60519C39.4807 7.11676 39.8994 7.53421 40.4126 7.53421H47.4291C48.0531 7.51784 48.5458 7.65289 48.9071 7.93938C49.2684 8.22586 49.449 8.63101 49.449 9.15487C49.449 9.67872 49.2684 10.1166 48.9071 10.4195C48.5458 10.7223 48.0531 10.8738 47.4291 10.8738H40.4496C39.9118 10.8738 39.4766 11.3076 39.4766 11.8437V13.1697C39.4766 13.7058 39.9118 14.1397 40.4496 14.1397H47.4291C48.2173 14.1233 48.8414 14.2747 49.3012 14.5939C49.761 14.9132 49.9909 15.3429 49.9909 15.8831C49.9909 16.4724 49.761 16.9308 49.3012 17.2582C48.8414 17.5856 48.2173 17.7493 47.4291 17.7493H40.4414C39.9118 17.7493 39.4807 18.179 39.4807 18.707V20.9538C39.4807 21.1093 39.6038 21.2321 39.7598 21.2321H47.9956C49.3423 21.2321 50.5123 21.0398 51.5141 20.6551C52.5159 20.2704 53.2877 19.7138 53.8255 18.9853C54.3675 18.2568 54.6384 17.4096 54.6384 16.4479C54.6384 15.3347 54.3223 14.3852 53.6901 13.6035C53.0578 12.8177 52.1915 12.3185 51.0953 12.1056V12.0975Z"
          fill="white"
        />
        <path
          d="M62.4595 4.59982L55.4677 20.8311C55.3897 21.0152 55.5252 21.2198 55.7263 21.2198H64.656C64.812 21.2198 64.9351 21.093 64.9351 20.9415V18.707C64.9351 18.179 64.5082 17.7534 63.9785 17.7534H61.2976L64.7668 8.93797C64.812 8.82338 64.9762 8.82338 65.0213 8.93797L68.585 18.179L69.6976 21.0439C69.7387 21.1503 69.8413 21.2239 69.9604 21.2239H74.148C74.3492 21.2239 74.4847 21.0193 74.4067 20.8351L67.5791 4.6121C67.4313 4.25604 67.0823 4.02686 66.6964 4.02686H63.3381C62.9563 4.02686 62.6114 4.25604 62.4595 4.60391V4.59982Z"
          fill="white"
        />
        <path
          d="M88.8198 5.60678C87.6046 4.55089 85.9008 4.02295 83.7002 4.02295H76.4128C76.2486 4.02295 76.1172 4.15391 76.1172 4.31762V7.3134C76.1172 7.4771 76.2486 7.60806 76.4128 7.60806H83.4785C84.3981 7.60806 85.1084 7.83315 85.6093 8.28334C86.1102 8.73353 86.3606 9.37606 86.3606 10.211C86.3606 11.0458 86.1102 11.7416 85.6093 12.2C85.1084 12.6583 84.3981 12.8875 83.4785 12.8875H77.082C76.5524 12.8875 76.1213 13.3172 76.1213 13.8452V20.9335C76.1213 21.0973 76.2527 21.2282 76.4169 21.2282H80.3336C80.4978 21.2282 80.6292 21.0973 80.6292 20.9335V16.7509C80.6292 16.5954 80.7565 16.4685 80.9125 16.4685H83.7084C85.9049 16.4685 87.6128 15.9078 88.828 14.7865C90.0433 13.6651 90.6509 12.0935 90.6509 10.0636C90.6509 8.14828 90.0433 6.66677 88.828 5.61088L88.8198 5.60678Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_40_214"
          x1="17.8136"
          y1="0.458526"
          x2="29.9313"
          y2="9.30229"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_40_214"
          x1="2.28507"
          y1="10.6201"
          x2="13.6367"
          y2="0.127662"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_40_214"
          x1="0.340684"
          y1="13.8865"
          x2="7.11378"
          y2="27.0207"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_40_214"
          x1="8.72945"
          y1="29.336"
          x2="24.3429"
          y2="29.336"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_40_214"
          x1="30.5911"
          y1="11.9424"
          x2="27.4816"
          y2="26.7656"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default LogoBAP
