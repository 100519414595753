import React, { useState } from 'react'

import MockBlogData from '@/components/Common/Pages/BlogDetail/BlogsMokup.json'

const BlogComponent: React.FC = () => {
  const [searchItem, setSearchItem] = useState('')
  const [filteredBlog, setFilteredBlog] = useState(MockBlogData)

  const handleInputChange = (e: any) => {
    const searchInput = e.target.value
    setSearchItem(searchInput)

    const filteredItems = MockBlogData.filter((blog) =>
      blog.title.includes(searchInput.toLowerCase())
    )
    setFilteredBlog(filteredItems)
  }

  return (
    <section className="blog">
      <div className="main-container blog-wrapper">
        <div className="search-bar">
          <input
            className="search-bar__input"
            placeholder="Search"
            value={searchItem}
            onChange={handleInputChange}
          ></input>
        </div>

        <div className="card-wrapper">
          {filteredBlog.map((val, index) => (
            <div key={index} className="card">
              <span> </span>
              <span> </span>
              <span> </span>
              <span> </span>

              <div className="card__thumbnail">
                <a href={`/blog/${val.slug}`}>
                  <img className="card__image" src={val.image} />
                </a>
              </div>

              <div className="card__information">
                <div className="card__category">
                  <p
                    className={`category category--${val.category.replaceAll(
                      ' ',
                      '-'
                    )}`}
                  >
                    {val.category}
                  </p>
                </div>

                <div className="card__title">
                  <h3>
                    <a href={`/blog/${val.slug}`}>{val.title}</a>
                  </h3>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default BlogComponent
