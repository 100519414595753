import React from 'react'

import useScrollToTop from '@/components/CustomHook/useScrollToTop'
import IconRoundTop from '@/components/Icons/IconRoundTop'

const ButtonGoToTop: React.FC = () => {
  const { goToTop, handleGoTop } = useScrollToTop()
  return (
    <>
      {goToTop && (
        <button className="btn-back-top" onClick={() => handleGoTop()}>
          <IconRoundTop width={25} height={25} />
        </button>
      )}
    </>
  )
}

export default ButtonGoToTop
