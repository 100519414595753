import React, { useCallback } from 'react'
import { useEffect, useRef, useState } from 'react'
interface Props {
  video: string
  isOpen: boolean
  onClose: () => void
}

const Popup: React.FC<Props> = ({ video, isOpen, onClose }) => {
  const [url, setUrl] = useState(video)
  const [open, setOpen] = useState(isOpen)
  const videoRef = useRef<HTMLVideoElement | null>(null)

  useEffect(() => {
    setOpen(isOpen)
    setUrl(video)
  }, [isOpen, video])

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        videoRef.current &&
        !videoRef.current.contains(event.target as Node)
      ) {
        onClose()
      }
    },
    [onClose, videoRef]
  )

  useEffect(() => {
    // Attach the mousedown event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside)

    // Detach the mousedown event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside])
  return (
    <div
      className="popup-media"
      style={{ display: open === false ? 'none' : 'block' }}
    >
      <span onClick={onClose}>&times;</span>
      <video ref={videoRef} src={url} loop autoPlay controls />
    </div>
  )
}

export default Popup
