import { FC } from 'react'

interface Props {
  width: number
  height: number
  color?: string | null
}

const IconPrevMusic: FC<Props> = ({ width, height, color }) => {
  const strokeColor = (color !== undefined ? color : '#fff') as string
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={strokeColor}
      width={width}
      height={height}
      viewBox="0 0 64 64"
      id="music"
    >
      <path d="M32 0C23.453 0 15.417 3.329 9.374 9.374 3.329 15.417 0 23.453 0 32s3.329 16.583 9.373 22.626C15.417 60.671 23.453 64 32 64s16.583-3.329 22.626-9.374C60.671 48.583 64 40.547 64 32s-3.329-16.583-9.373-22.626C48.583 3.329 40.547 0 32 0zm19.798 51.798C46.51 57.087 39.479 60 32 60s-14.51-2.913-19.798-8.202C6.913 46.51 4 39.479 4 32s2.913-14.51 8.202-19.798C17.49 6.913 24.521 4 32 4s14.51 2.913 19.798 8.202C57.087 17.49 60 24.521 60 32s-2.913 14.51-8.202 19.798z"></path>
      <path d="M28.875 19.201a2.002 2.002 0 00-2.11.227l-14 11a1.999 1.999 0 00-.001 3.144l14 11a2.003 2.003 0 002.11.227A2 2 0 0030 43V21a2 2 0 00-1.125-1.799zM26 38.885L17.237 32 26 25.115v13.77zM48.875 19.201a2.001 2.001 0 00-2.11.227l-14 11a1.999 1.999 0 00-.001 3.144l14 11a2.003 2.003 0 002.11.227A2 2 0 0050 43V21a2 2 0 00-1.125-1.799zM46 38.885L37.237 32 46 25.115v13.77z"></path>
    </svg>
  )
}

export default IconPrevMusic
