/* eslint-disable react/prop-types */
import { Link } from 'react-router-dom'

import BranchCompany from '@/components/Common/Item/BranchCompany'
import projectInformation from '@/components/Common/Item/Product/projectInformation.json'
import useScrollToSection from '@/components/CustomHook/useScrollToSection'
import {
  IconFacebook,
  IconInstagram,
  IconTwitter,
  LogoBAP,
} from '@/components/Icons'

const Footer: React.FC = () => {
  const { scrollToSection } = useScrollToSection()

  return (
    <footer className="footer">
      <div className="main-container">
        <div className="wrapper-footer">
          <div className="company-branch">
            <div className="logo-footer">
              <LogoBAP width={91} height={32} />
            </div>
            <div className="icon-footer">
              <Link to={''}>
                <IconFacebook width={20} height={20} />
              </Link>
              <Link to={''}>
                <IconInstagram width={20} height={20} />
              </Link>
              <Link to={''}>
                <IconTwitter width={20} height={20} />
              </Link>
            </div>
            <div className="infor-branch">
              <BranchCompany
                city="Ha Noi, Viet Nam"
                location="3F, 6th Element Tower, Nguyen Van Huyen St, Xuan La Ward, Tay Ho Dist, Ha Noi"
                phone="(+84) 986 241 041"
                mail="sales@bap.jp"
              />
              <BranchCompany
                city="Hue, Viet Nam"
                location="HCC Building, 2F, 28 Ly Thuong Kiet – Vinh Ninh – Hue"
                phone="(+84) 0234 6273 555"
                mail="sales@bap.jp"
              />
              <BranchCompany
                city="Da Nang, Viet Nam"
                location="BAP Building 180-182-184 Nguyen Tri Phuong, Chinh Gian, Thanh Khe District, Da Nang"
                phone="(+84) 0236 6565 115"
                mail="sales@bap.jp"
              />
              <BranchCompany
                city="Ho Chi Minh, Viet Nam"
                location="BAP Building, 236/29/18 Dien Bien Phu, Ward 17, Binh Thanh District, Ho Chi Minh"
                phone="(+84) 028 6270 1557"
                mail="sales@bap.jp"
              />
            </div>
          </div>
          <div className="ft-service">
            <h3>Service</h3>
            <ul>
              <li>
                <Link to={''}>Text Recognition</Link>
              </li>
              <li>
                <Link to={''}>Image Proccesing</Link>
              </li>
              <li>
                <Link to={''}>Natural Language Processing</Link>
              </li>
              <li>
                <Link to={''}>Voice Recognition</Link>
              </li>
              <li>
                <Link to={''}>Traing-cost optimization</Link>
              </li>
            </ul>
          </div>
          <div className="ft-product">
            <h3>Product</h3>
            <ul>
              {projectInformation.map((project, index) => (
                <li key={index}>
                  <a href={'/product/' + project.slug}>{project.name}</a>
                </li>
              ))}
            </ul>
          </div>
          <div className="ft-company-infor">
            <h3>Company information</h3>
            <ul>
              <li>
                <Link to={''} onClick={() => scrollToSection('about-us')}>
                  About Us
                </Link>
              </li>
              <li>
                <Link to={''} onClick={() => scrollToSection('contact')}>
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
